<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :footer-left-actions="footerLeftActions"
      :sums="sums"
      :is-page-show="false"
      api-url="/orders-list"
      count-url="/orders-count"
      @orderCancel="orderCancel"
      @showWaitReturnOrder="showWaitReturnOrder"
      @showNoReturnOrder="showNoReturnOrder"
      @showChangeItem="showChangeItem"
      @showChangeReceiptedItem="showChangeReceiptedItem"
      @downloadExcelV1="downloadExcelV1"
      @downloadExcelV2="downloadExcelV2"
      @downloadExcelOutOfStock="downloadExcelOutOfStock"
      @downloadExcelPackage="downloadExcelPackage"
      @showDialogPreventPickup="showDialogPreventPickup"
      @showDialogChangeItemOrderMultiple="showDialogChangeItemOrderMultiple"
    ></custom-table>

    <v-dialog v-model="dialogChangeItem" persistent max-width="1200px">
      <ChangeItem
        v-if="dialogChangeItem"
        @cancel="hideChangeItem"
        :order="orderChanging"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="dialogChangeReceiptedItem" persistent max-width="1200px">
      <ChangeReceiptedItem
        v-if="dialogChangeReceiptedItem"
        @cancel="hideChangeReceiptedItem"
        :order="orderChanging"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogChangeItemOrderMultiple"
      persistent
      max-width="1200px"
    >
      <ChangeItemOrderMultiple
        v-if="dialogChangeItemOrderMultiple"
        @cancel="hideDialogChangeItemOrderMultiple"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>

    <v-dialog v-model="dialogWaitReturn" max-width="1024px">
      <WaitReturn v-if="dialogWaitReturn" @cancel="hideWaitReturnOrder" />
    </v-dialog>

    <v-dialog v-model="dialogNoReturn" max-width="1024px">
      <NoReturn v-if="dialogNoReturn" @cancel="hideNoReturnOrder" />
    </v-dialog>

    <v-dialog v-model="dialogPreventPickup" persistent max-width="640px">
      <PreventPickupMultiple
        v-if="dialogPreventPickup"
        @cancel="hideDialogPreventPickup"
        @refreshData="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  DELIVERY_STATE,
  GOODS_ISSUE_STATE,
  STAMP_STATUS,
  CUSTOM_VIEW_WAREHOUSE_ORDER_ONLINE,
  YES_NO_OPTIONS,
  MAX_SELECT_RANGE,
} from "@/libs/const";
import moment from "moment";

export default {
  name: "Index",
  components: {
    ChangeItem: () => import("@/components/orders/ChangeItem"),
    ChangeReceiptedItem: () =>
      import("@/components/orders/ChangeReceiptedItem"),
    ChangeItemOrderMultiple: () =>
      import("@/components/orders/ChangeItemOrderMultiple"),
    WaitReturn: () => import("@/components/orders/WaitReturn"),
    NoReturn: () => import("@/components/orders/NoReturn"),
    PreventPickupMultiple: () =>
      import("@/components/orders/PreventPickupMultiple"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_WAREHOUSE_ORDER_ONLINE,
    columns: [],
    footerLeftActions: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    filters: {
      create_time: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD"),
      ],
    },
    statusOptions: [...GOODS_ISSUE_STATE],
    deliveryStateOption: [...DELIVERY_STATE],
    stampStatus: [...STAMP_STATUS],
    yesNoOptions: [...YES_NO_OPTIONS],
    dialogChangeItem: false,
    dialogChangeReceiptedItem: false,
    dialogWaitReturn: false,
    dialogNoReturn: false,
    dialogPreventPickup: false,
    dialogChangeItemOrderMultiple: false,
    orderChanging: {},
  }),
  created() {
    const columns = [
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse_1"),
        placeholder: this.$t("labels.warehouse_1"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_ids",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        allowPaste: true,
        required: true,
        component: "OrderTracking",
        buttonActions: [
          {
            action: "orderCancel",
            text: this.$t("labels.cancel"),
            color: "error",
            permissions: [],
            allowKey: "can_cancel",
          },
          {
            action: "showChangeItem",
            text: this.$t("labels.change"),
            color: "success",
            class: "mt-1",
            permissions: [],
            allowKey: "can_change",
          },
          {
            action: "showChangeReceiptedItem",
            text: this.$t("labels.change_receipted"),
            color: "purple",
            class: "mt-1 white--text",
            permissions: [],
            allowKey: "can_change_receipted",
          },
        ],
      },
      {
        type: "input-filter",
        label: this.$t("labels.customer_order_id"),
        placeholder: this.$t("labels.customer_order_id"),
        name: "customer_order_ids",
        hasSort: false,
        sortName: "customer_order_id",
        key: "customer_order_id",
        allowPaste: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.delivery_order_id_1"),
        placeholder: this.$t("labels.delivery_order_id_1"),
        name: "delivery_order_ids",
        hasSort: false,
        sortName: "delivery_order_id",
        key: "delivery_order_id",
        component: "DeliveryTracking",
        allowPaste: true,
      },
      {
        type: "select-delivery-company",
        label: this.$t("labels.delivery_company"),
        placeholder: this.$t("labels.delivery_company"),
        name: "id_delivery_company",
        hasSort: false,
        sortName: "delivery_company_name",
        key: "delivery_company_name",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time_2"),
        placeholder: this.$t("labels.create_time_2"),
        name: "create_time",
        hasSort: false,
        sortName: "create_time",
        key: "create_time",
        required: true,
        defaultValue: [
          moment().startOf("week").format("YYYY-MM-DD"),
          moment().endOf("week").format("YYYY-MM-DD"),
        ],
        allowNull: false,
        clearable: false,
        maxRange: MAX_SELECT_RANGE,
      },
      /* {
        type: "date-range-filter",
        label: this.$t("labels.finish_delivery_time"),
        placeholder: this.$t("labels.finish_delivery_time"),
        name: "finish_delivery_time",
        hasSort: false,
        sortName: "finish_delivery_time",
        key: "finish_delivery_time",
      }, */
      {
        type: "select-filter",
        label: this.$t("labels.warehouse_status"),
        placeholder: this.$t("labels.warehouse_status"),
        name: "state",
        hasSort: false,
        sortName: "goods_issue_state_name",
        key: "state",
        required: true,
        options: this.statusOptions,
      },
      {
        type: "input-filter",
        label: this.$t("labels.delivery_status"),
        placeholder: this.$t("labels.delivery_status"),
        name: "delivery_partner_state",
        hasSort: false,
        sortName: "delivery_partner_state",
        key: "delivery_partner_state",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.summary_status"),
        placeholder: this.$t("labels.summary_status"),
        name: "id_delivery_state",
        hasSort: false,
        sortName: "delivery_state_name",
        key: "id_delivery_state",
        hiddenZero: true,
        required: true,
        options: this.deliveryStateOption,
      },
      {
        type: "input-filter",
        label: this.$t("labels.export"),
        placeholder: this.$t("labels.export"),
        name: "barcode",
        hasSort: false,
        sortName: "barcode",
        key: "item_html",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.receiver"),
        placeholder: this.$t("labels.receiver"),
        name: "receiver_name",
        hasSort: false,
        sortName: "receiver_name",
        key: "receiver_name",
      },
      {
        type: "input-filter",
        label: this.$t("labels.phone_number"),
        placeholder: this.$t("labels.phone_number"),
        name: "receiver_phone_number",
        hasSort: false,
        sortName: "receiver_phone_number",
        key: "receiver_phone_number",
      },
      {
        type: "input-filter",
        label: this.$t("labels.address"),
        placeholder: this.$t("labels.address"),
        name: "address",
        hasSort: false,
        sortName: "address",
        key: "address",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.cod"),
        placeholder: this.$t("labels.cod"),
        name: "cash_on_delivery",
        hasSort: false,
        sortName: "cash_on_delivery",
        key: "cash_on_delivery",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.money_collected"),
        placeholder: this.$t("labels.money_collected"),
        name: "money_collected",
        hasSort: false,
        sortName: "money_collected",
        key: "money_collected",
        required: true,
      },
      {
        type: "select-e-market",
        label: this.$t("labels.source"),
        placeholder: this.$t("labels.source"),
        class: "c-input-xs input-filter",
        name: "id_e_market",
        hasSort: false,
        sortName: "e_market_name",
        key: "e_market_name",
        params: {
          "is-filter": true,
        },
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_e_market_shop",
        hasSort: false,
        sortName: "customer_e_market_shop_name",
        key: "customer_e_market_shop_name",
        params: {
          "is-check-e-market": false,
        },
      },
      {
        type: "select-other-source",
        label: this.$t("labels.other_source"),
        placeholder: this.$t("labels.other_source"),
        name: "id_source",
        hasSort: false,
        sortName: "id_source",
        key: "source_name",
      },
      {
        type: "select-filter",
        label: this.$t("labels.stamp"),
        placeholder: this.$t("labels.stamp"),
        name: "stamp_file",
        hasSort: false,
        sortName: "stamp_file",
        key: "stamp_file",
        options: this.stampStatus,
      },
      {
        type: "select-filter",
        label: this.$t("labels.is_request_find_goods"),
        placeholder: this.$t("labels.is_request_find_goods"),
        name: "is_request_find_goods",
        hasSort: false,
        sortName: "is_request_find_goods",
        key: "is_request_find_goods",
        options: this.yesNoOptions,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_creator",
        hasSort: false,
        sortName: "creator_name",
        key: "creator_name",
      },
      {
        type: "",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        class: "c-red--text",
        name: "note",
        hasSort: false,
        sortName: "note",
        key: "note",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.deadline_for_lsr"),
        placeholder: this.$t("labels.deadline_for_lsr"),
        name: "deadline_for_lsr",
        hasSort: false,
        sortName: "deadline_for_lsr",
        key: "deadline_for_lsr",
        component: "CountDown",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.deadline_for_confirm"),
        placeholder: this.$t("labels.deadline_for_confirm"),
        name: "deadline_for_confirm",
        hasSort: false,
        sortName: "deadline_for_confirm",
        key: "deadline_for_confirm",
        component: "CountDown",
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.deadline_for_handover"),
        placeholder: this.$t("labels.deadline_for_handover"),
        name: "deadline_for_handover",
        hasSort: false,
        sortName: "deadline_for_handover",
        key: "deadline_for_handover",
        component: "CountDown",
      },
    ];
    this.columns = [...columns];

    const footerLeftActions = [
      {
        text: "Excel",
        action: "downloadExcelV2",
        link: "",
        permissions: [],
        color: "info",
        class: "mr-1",
      },
      {
        text: "Excel V1",
        action: "downloadExcelV1",
        link: "",
        permissions: [],
        color: "success",
        class: "mr-1",
      },
      {
        text: this.$t("labels.prevent_pickup"),
        action: "showDialogPreventPickup",
        link: "",
        permissions: ["prevent_pickup_multiple"],
        color: "purple",
        class: "mr-1 white--text",
      },
      {
        text: this.$t("labels.change_item_order_multiple"),
        action: "showDialogChangeItemOrderMultiple",
        link: "",
        permissions: ["order_change_item_multiple"],
        color: "purple",
        class: "mr-1 white--text",
      },
    ];
    this.footerLeftActions = [...footerLeftActions];

    const footerActions = [
      {
        text: this.$t("labels.out_of_stock"),
        action: "downloadExcelOutOfStock",
        link: "",
        permissions: [],
        color: "warning",
        class: "mr-1",
      },
      {
        text: this.$t("labels.package"),
        action: "downloadExcelPackage",
        link: "",
        permissions: [],
        color: "error",
        class: "mr-1",
      },
      {
        text: this.$t("labels.order_wait_return"),
        action: "showWaitReturnOrder",
        link: "",
        permissions: [],
        color: "primary",
        class: "mr-1",
      },
      {
        text: this.$t("labels.order_no_return"),
        action: "showNoReturnOrder",
        link: "",
        permissions: [],
        color: "purple",
        class: "mr-1 white--text",
      },
    ];
    this.footerActions = [...footerActions];

    const sums = [
      {
        text: this.$t("labels.order"),
        key: "sumOrder",
      },
    ];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },

    showWaitReturnOrder() {
      this.dialogWaitReturn = true;
    },

    hideWaitReturnOrder() {
      this.dialogWaitReturn = false;
    },

    showNoReturnOrder() {
      this.dialogNoReturn = true;
    },

    hideNoReturnOrder() {
      this.dialogNoReturn = false;
    },

    showDialogPreventPickup() {
      this.dialogPreventPickup = true;
    },

    hideDialogPreventPickup() {
      this.dialogPreventPickup = false;
    },

    showDialogChangeItemOrderMultiple() {
      this.dialogChangeItemOrderMultiple = true;
    },

    hideDialogChangeItemOrderMultiple() {
      this.dialogChangeItemOrderMultiple = false;
    },

    async downloadHandoverFile(orderId) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "bien-ban-ban-giao.xlsx";

      try {
        await this.downloadExcelFile(
          "/download-handover-file",
          { id: orderId },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async downloadExcelOutOfStock(filters) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "thieu-hang.xlsx";

      try {
        await this.downloadExcelFile(
          "/orders-export-out-of-stock",
          filters,
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async downloadExcelV1(filters) {
      if (window.exportWaiting) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      try {
        await httpClient.post("/orders-export-list-v1", filters);
        window.exportWaiting = true;
        this.$vToastify.warning(this.$t("messages.loading"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async downloadExcelV2(filters) {
      if (window.exportWaiting) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      try {
        await httpClient.post("/orders-export-list", filters);
        window.exportWaiting = true;
        this.$vToastify.warning(this.$t("messages.loading"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async downloadExcelPackage(filters) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "bao-bi-su-dung.xlsx";

      try {
        await this.downloadExcelFile(
          "/orders-export-package",
          filters,
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    hideChangeItem() {
      this.dialogChangeItem = false;
      this.orderChanging = {};
    },
    showChangeItem(order) {
      this.dialogChangeItem = true;
      this.orderChanging = { ...order };
    },
    showChangeReceiptedItem(order) {
      this.dialogChangeReceiptedItem = true;
      this.orderChanging = { ...order };
    },
    hideChangeReceiptedItem() {
      this.dialogChangeReceiptedItem = false;
      this.orderChanging = {};
    },
    async orderCancel(item) {
      if (!confirm(this.$t("messages.are_you_sure_cancel_order"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/orders-cancel", {
          id_order: item.id,
        });
        this.isLoading = false;
        this.refreshCustomTable();
        this.$vToastify.warning(this.$t("messages.order_cancel_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
